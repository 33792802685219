import * as React from 'react'
import { css, styled } from 'styled-components'

import Layout from '../components/layouts/layout'
import Seo from '../components/seo'

const TextStyle = css`
  align-items: center;
  color: ${props => props.theme.black300greyscale200};
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
  width: 100vw;
`

const Heading = styled.h1`
  ${TextStyle}
  font: 900 var(--fontSize-5) / 1.5 var(--font-montserrat);
  letter-spacing: var(--letterSpacing-5);
  margin: 2rem auto auto auto;
  @media (min-width: 980px) {
    margin: 6rem auto auto auto;
  }
`

const Subheading = styled.p`
  ${TextStyle}
  font: 400 var(--fontSize-1) / 1.5 var(--font-montserrat);
  letter-spacing: var(--letterSpacing-0);
  margin: auto auto 2rem auto;
`

interface Props {
  data: any
  location: any
}

export default function NotFoundPage({ data, location }: Props): JSX.Element {
  return (
    <Layout location={location}>
      <Heading>404: Not Found</Heading>
      <Subheading>You just hit a route that doesn&#39;t exist... the sadness.</Subheading>
    </Layout>
  )
}

export function Head({ location }: any): JSX.Element {
  return <Seo title="404: Not Found" location={location} description={''} />
}
